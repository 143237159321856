.container-flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.flex-5 {
    flex: 5;
}
.flex-6 {
    flex: 6;
}
.flex-7 {
    flex: 7;
}
.flex-8 {
    flex: 8;
}
.flex-9 {
    flex: 9;
}
.flex-10 {
    flex: 10;
}
.full-width {
    width: 100% !important;
}
.align-center {
    text-align: center !important;
}
.m-t-15 {
    margin-top: 15px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-t-30 {
    margin-top: 30px;
}
.m-t-5 {
    margin-top: 5px;
}
.m-p-t-0 {
    margin-top: 0px;
    padding-top: 0px;
}
.m-b-30 {
    margin-bottom: 30px;
} 
.p-b-0 {
    padding-bottom: 0px;
}
.color-white {
    color: white;
}
.no-margin {
    margin: 0 !important;
}
@media (min-width: 600px) {
	html, body {
		font-size: 19px !important;
	}
}
.mobile-width {
	max-width: 600px !important;
}